import React from 'react';
import { observer } from 'mobx-react';
import { Spin, Button, Row, Col, Modal, Space } from 'antd';
import { StepsComponent } from '../shelvesBase';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import Store from './store';
import styles from './index.less';
import successIcon from '../../assets/images/succesIcon.png';

const store = new Store();

export default observer(() => {
  const { loading, currentStep, onSave, onClickCloseButton, onClickMyStyleButton, onClickContinueButton, successModalVisible, gmsStyleIds } = store;
  return (
    <div className={styles.container}>
      <Spin spinning={loading}>
        <StepsComponent currentStep={currentStep}/>
        <div
          className={styles.stepContent}
        >
          {currentStep < 1 ? <StepOne store={store}/> : <StepTwo store={store}/>}
        </div>
        {currentStep > 0 && (
          <div className={styles.footer}>
            <div className={styles.buttonWrapper}>
              <Row>
                <Col offset={6}>
                  <Button
                    onClick={onSave}
                    type="primary"
                  >
                    批量铺货
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Spin>
      <Modal
        centered
        closable={false}
        closeIcon={null}
        footer={null}
        maskClosable={false}
        open={successModalVisible}
        title={null}
        width={390}
      >
        <div className={styles.successModal}>
          <img
            className={styles.successIcon}
            src={successIcon}
          />
          <div className={styles.title}>
            已提交
          </div>
          <div className={styles.tips}>
            请耐心等待，稍后去“我的款式”查看上架结果
          </div>
          <Space
            className={styles.buttonWrapper}
            size={12}
          >
            <Button
              ghost
              onClick={onClickCloseButton}
              type="primary"
            >
              关闭
            </Button>
            <Button
              ghost
              onClick={onClickMyStyleButton}
              type="primary"
            >
              我的款式
            </Button>
            <Button
              onClick={onClickContinueButton}
              type="primary"
            >
              继续选款
            </Button>
          </Space>
        </div>
      </Modal>
    </div>
  );
});

import React from 'react';
import { observer } from 'mobx-react';
import { Affix, Row, Col, Button } from 'antd';
import { CategorySelector } from '../../shelvesBase';
import type Store from '../store';

export default observer((props: { store: Store; }) => {
  const { categorySelectorStore, onClickNextButton } = props.store;
  const { canNext } = categorySelectorStore;
  return (
    <div>
      <CategorySelector store={categorySelectorStore}/>
      <Affix offsetBottom={0}>
        <Row>
          <Col
            offset={4}
            span={20}
          >
            <Button
              disabled={!canNext}
              onClick={onClickNextButton}
              type="primary"
            >
              下一步
            </Button>
          </Col>
        </Row>
      </Affix>
    </div>
  );
});

import React from 'react';
import { action, computed, observable } from 'mobx';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import type { FormInstance } from 'antd';
import _ from 'lodash';
import { CategorySelectorStore } from '../shelvesBase';
import type { GoodsCatRule, GoodsServiceRule, IFormValues, TwoPiecesDiscountRule } from './interface';
import { SHIP_LIMIT_DICT } from './constants';

export default class Store {
  constructor() {
    const params = new URLSearchParams(window.location.search);
    this.shopIds = params.get('shopIds')?.split(',')
      .map((i) => Number(i));
    this.pageId = params.get('pageId');

    // url上商品id集合格式 ids=gmsStyleId1,posGooldsId1;gmsStyleId2,posGooldsId2;
    const ids = params.get('ids') ?? '';
    this.productIdCollection = ids.split(';').map((i) => {
      const [
        gmsStyleId,
        posGoodsId,
      ] = i.split(',');
      return {
        gmsStyleId: Number(gmsStyleId),
        posGoodsId: Number(posGoodsId),
      };
    });

    this.init();
  }

  public categorySelectorStore = new CategorySelectorStore();

  // 所选店铺列表
  public shopIds: number[] = [];

  // 商品id集合（保存的时候用）
  @observable private productIdCollection: Array<{
    gmsStyleId: number;
    posGoodsId: number;
  }> = [];

  // 拼多多平台类型
  private platformType = 17;

  // 缓存页面id，关闭时用
  private pageId = '';

  private categoryId: string | number;

  public formRef = React.createRef<FormInstance<IFormValues>>();

  @observable public currentStep = 0;

  @observable public loading = false;

  // 货源地
  @observable public countryOptions: Array<{
    label: string;
    value: number;
  }> = [];

  // 商品类型列表
  @observable public productTypeOptions: Array<{
    label: string;
    value: number;
  }> = [];

  // 满两件折扣规则
  @observable public twoPiecesDiscountRule: TwoPiecesDiscountRule = {} as any;

  // 商品类型对应的发货时间map
  @observable public goodsServiceRuleMap: Record<number, GoodsServiceRule> = {} as any;

  // 承诺发货时间列表
  @observable public shipmentLimitOptions: Array<{
    label: string;
    value: number;
  }> = [];

  // 商品级联属性
  @observable private linkedProps = {};

  // 商品级联keys
  @observable private linkPropsKeys = [];

  // 商品属性
  @observable public goodsProps = [];

  // 提交成功弹窗
  @observable public successModalVisible = false;

  // 款式id列表
  @computed public get gmsStyleIds() {
    return this.productIdCollection.map((i) => i.gmsStyleId);
  }

  // pos商品id列表
  @computed private get posGoodsIds() {
    return this.productIdCollection.map((i) => i.posGoodsId);
  }

  @action
  private init = async() => {
    try {
      this.loading = true;
      this.getCountry();
      await this.categorySelectorStore.initData(String(this.posGoodsIds[0]), String(this.platformType), String(this.shopIds[0]), {
        shopIds: this.shopIds,
        gmsStyleIds: this.gmsStyleIds,
      });
    } finally {
      this.loading = false;
    }
  };

  /**
   * 获取货源地
   */
  @action
  private getCountry = async() => {
    const { data } = await request<BaseData<Array<{
      countryId: number;
      countryName: string;
    }>>>({
      url: '/api/gms/goods/platform/pdd/listCountry',
      method: 'POST',
      data: { shopId: this.shopIds[0] },
    });
    if (Array.isArray(data)) {
      this.countryOptions = data.map((i) => ({
        label: i.countryName,
        value: i.countryId,
      }));
    }
  };

  /**
   * 进入第二步根据选择的类目获取发布规则
   */
  @action
  public onClickNextButton = async() => {
    const categoryIds = this.categorySelectorStore.selectedValue;
    this.categoryId = categoryIds[categoryIds.length - 1];
    try {
      this.loading = true;
      await this.getGoodsCatRule();
      this.currentStep++;
    } finally {
      this.loading = false;
    }
  };

  /**
   * 获取产品发布规则
   */
  @action
  private getGoodsCatRule = async() => {
    const { data: {
      goodsServiceRule: {
        goodsTypeNameMap,
        goodsServiceRuleMap,
      },
      twoPiecesDiscountRule,
      goodsProps,
      linkedPropsMap,
    }} = await request<BaseData<GoodsCatRule>>({
      url: '/api/gms/goods/platform/pdd/batchStyle/getGoodsCatRule',
      method: 'POST',
      data: {
        categoryId: this.categoryId,
        gmsStyleIds: this.gmsStyleIds,
        shopIds: this.shopIds,
      },
    });

    // 处理类目属性
    this.linkedProps = linkedPropsMap;
    this.dealGoodsProps(goodsProps, linkedPropsMap);
    this.goodsProps = goodsProps;

    // 年份季节倒序排列
    this.goodsProps.forEach((item) => {
      // 补丁代码:类目为上市时节 按照最新年份倒序排列
      if (item.id === '1906') {
        item.checkOptions = _.sortBy(item.checkOptions, (o) => Number(o.value.slice(0, 4))).reverse();
      }
    });

    this.goodsServiceRuleMap = goodsServiceRuleMap;
    this.twoPiecesDiscountRule = twoPiecesDiscountRule;

    // 处理商品类型
    // @ts-ignore
    this.productTypeOptions = Object.entries(goodsTypeNameMap).map(([
      value,
      label,
    ]) => ({
      label,
      value: Number(value),
    }))
      .slice(0, 1);// 目前只显示普通商品

    this.onGoodsTypeChange(1); // 展示承诺发货时间

    // 表单初始化
    setTimeout(() => {
      this.formRef.current.setFieldsValue({
        skuMultiPriceType: 0,
        skuPriceType: 0,
        secondHand: false,
        preSaleType: 0,
        goodsType: 1,
        shipmentLimitSecond: this.shipmentLimitOptions[0]?.value,
        refundable: true,
        operateType: 0,
      });
    }, 100);
  };

  /**
   * 修改商品类型联动修改承诺发货时间
   */
  @action
  public onGoodsTypeChange = (value: number) => {
    this.shipmentLimitOptions = this.goodsServiceRuleMap[value].shipmentLimitSecondList.slice(0).map((i) => ({
      label: SHIP_LIMIT_DICT[i],
      value: i,
    }));

    // 允许当日发货
    if (this.goodsServiceRuleMap[value].deliveryOneDayRule) {
      this.shipmentLimitOptions.unshift({
        label: SHIP_LIMIT_DICT[0],
        value: 0,
      });
    }
  };

  /**
   * 处理商品属性联动关系
   */
  @action private dealGoodsProps = (goodsProps, linkedPropsMap) => {
    const linkPropsKeys = Object.keys(linkedPropsMap);
    this.linkPropsKeys = linkPropsKeys;
    for (let i = 0; i < goodsProps.length; i++) {
      if (linkPropsKeys.some((keyItem) => keyItem.split('_')[0] === goodsProps[i].id)) {
        if (goodsProps[i].value) {
          const propsItem = linkedPropsMap[`${goodsProps[i].id}_${goodsProps[i].value}`];
          goodsProps.splice(i + 1, 0, propsItem);
        }
      }
    }
  };

  /**
   * 监听类目属性改变，联动处理
   */
  public onPropsItemChange = (key, val, index) => {
    if (val) {
      const mapKey = `${key}_${val}`;
      if (this.linkPropsKeys.includes(mapKey)) {
        // 有联动关系的项纪录上一次的值
        const changeIndex = this.goodsProps.findIndex((item) => item.id === key);
        this.goodsProps[changeIndex].lastVal = val;

        const addPropsItem = this.linkedProps[mapKey];
        if (this.goodsProps.find((item) => item.id === addPropsItem.id)) {
          this.goodsProps.splice(index + 1, 1, addPropsItem);
          this.formRef.current?.setFieldsValue({ [addPropsItem.id]: '' });
          this.onPropsItemChange(addPropsItem.id, '', index + 1);
        } else {
          this.goodsProps.splice(index + 1, 0, addPropsItem);
        }
      }
    } else {
      const deletePropsItem = this.goodsProps.splice(index + 1, 1);

      // 为性能考虑 暂不递归
      const mapKey = `${deletePropsItem[0].id}_${deletePropsItem[0].lastVal}`;
      if (this.linkPropsKeys.includes(mapKey)) {
        const childItem = this.linkedProps[mapKey];
        const childIndex = this.goodsProps.findIndex((item) => item.id === childItem.id);
        if (childIndex !== -1) {
          this.goodsProps.splice(childIndex, 1);
        }
      }
    }
  };

  /**
   * 监听预售类型改变
   */
  public onPreSaleTypeChange = (value: number) => {
    this.formRef.current.resetFields([
      'shipmentLimitDay',
      'preSaleTime',
    ]);
  };

  /**
   * 保存
   */
  @action
  public onSave = async() => {
    const formValues = this.formRef.current.getFieldsValue();
    const productPropsFields = this.goodsProps.filter((i) => i.required).map((i) => String(i.id));
    const requiredFields = [
      'skuMultiPriceProfit',
      'skuPriceProfit',
      ...productPropsFields,
    ];

    // 海外进口商品校验货源地
    if (formValues.goodsType !== 1) {
      requiredFields.push('countryId');
    }

    // 预售类型不同校验字段不同
    if (formValues.preSaleType === 1) {
      requiredFields.push('preSaleTime');
    } else if (formValues.preSaleType === 2) {
      requiredFields.push('shipmentLimitDay');
    }

    // 满两件折扣校验
    if (this.twoPiecesDiscountRule.ifMustTwoPiecesDiscount) {
      requiredFields.push('twoPiecesDiscount');
    }
    await this.formRef.current.validateFields(requiredFields);

    const saveData = {
      categoryId: this.categoryId,
      batchPutOnShelfGmsStyleList: this.productIdCollection,
      shopIds: this.shopIds,
      skuMultiPriceProfit: formValues.skuMultiPriceProfit,
      skuMultiPriceType: formValues.skuMultiPriceType,
      skuPriceType: formValues.skuPriceType,
      skuPriceProfit: formValues.skuPriceProfit,
    };

    const platformExtends: Record<string, any> = {
      catId: this.categoryId,
      goodsType: formValues.goodsType,
      secondHand: formValues.secondHand,
      refundable: formValues.refundable,
      folt: formValues.folt,
      operateType: formValues.operateType,
      goodsProps: [],
    };

    // 处理商品属性
    platformExtends.goodsProps = this.goodsProps.map((cur) => {
      const { id, type, name } = cur;
      let eachValue;
      if (type !== 'INPUT') {
        eachValue = typeof formValues[id] === 'object' ? (formValues[id] && formValues[id].join(',')) : formValues[id];
      } else {
        eachValue = formValues[id];
      }
      return {
        id,
        name,
        value: eachValue,
      };
    }).filter((i) => i.value); // 过滤没有值的属性

    // 海外进口商品必须传货源地
    if (formValues.goodsType !== 1) {
      platformExtends.countryId = formValues.countryId;
    }

    // 处理发货时间
    if (formValues.preSaleType !== 2) {
      if (formValues.shipmentLimitSecond === 0) {
        platformExtends.deliveryOneDay = 1;
      } else {
        platformExtends.shipmentLimitSecond = formValues.shipmentLimitSecond;
      }
    }

    // 非预售
    if (formValues.preSaleType === 0) {
      platformExtends.preSale = false;
    }

    // 定时预售
    if (formValues.preSaleType === 1) {
      platformExtends.preSale = true;
      platformExtends.preSaleTime = formValues.preSaleTime?.endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }

    // 时段预售
    if (formValues.preSaleType === 2) {
      platformExtends.groupPreSale = 1;
      platformExtends.shipmentLimitSecond = formValues.shipmentLimitDay * 24 * 3600;
    }

    // 满两件折扣
    if (this.twoPiecesDiscountRule.ifMustTwoPiecesDiscount) {
      platformExtends.twoPiecesDiscount = formValues.twoPiecesDiscount;
    }

    try {
      this.loading = true;
      await request<BaseData>({
        url: '/api/gms/goods/platform/pdd/batchStyle/goodsPutOnShelf',
        method: 'POST',
        data: {
          ...saveData,
          platformExtends,
        },
      });
      this.successModalVisible = true;
    } finally {
      this.loading = false;
    }
  };

  /**
   * 关闭页面
   */
  public onClickCloseButton = () => {
    window.top.egenie.closeTab(this.pageId);
  };

  /**
   * 跳转我的款式页面
   */
  public onClickMyStyleButton = () => {
    window.top.egenie.openTabId(256);
  };

  /**
   * 跳转衫海精首页
   */
  public onClickContinueButton = () => {
    const isProd = window.location.host.includes('dap');
    const host = isProd ? 'https://shj.ejingling.cn' : '';
    window.open(`${host}/egenie-ts-vogue/homePage/index`);
  };
}

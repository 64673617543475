export const SHIP_LIMIT_DICT = {
  '0': '当日发',
  '86400': '24小时发',
  '172800': '48小时发',
  '432000': '5天发',
};

// 价格类型
export const PRICE_TYPE_OPTIONS = [
  {
    label: '固定金额',
    value: 0,
  },
  {
    label: '百分比',
    value: 1,
  },
];

// 是否二手
export const SECOND_HAND_OPTIONS = [
  {
    label: '非二手',
    value: false,
  },
  {
    label: '二手',
    value: true,
  },
];

// 预售
export const PRE_SALE_OPTIONS = [
  {
    label: '非预售',
    value: 0,
  },
  {
    label: '定时预售',
    value: 1,
  },
  {
    label: '时段预售',
    value: 2,
  },
];

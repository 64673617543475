import React from 'react';
import styles from './index.less';

const Title: React.FC<{
  title: string;
}> = (props) => {
  const { title } = props;
  return (
    <div className={styles.title}>
      {title}
    </div>
  );
};

export default Title;

import React from 'react';
import { observer } from 'mobx-react';
import { Alert, Form, Space, Select, InputNumber, Row, Col, Radio, Input, Checkbox, DatePicker, Spin, Affix, Button } from 'antd';
import moment from 'moment';
import Title from '../title';
import type Store from '../store';
import styles from './index.less';
import { PRICE_TYPE_OPTIONS, SECOND_HAND_OPTIONS, PRE_SALE_OPTIONS } from '../constants';

const RenderByCondition: React.FC<{
  visible: boolean;
}> = (props) => {
  const { visible, children } = props;
  return (
    <div style={{ display: visible ? 'block' : 'none' }}>
      {children}
    </div>
  );
};

const PROPS_LAYOUT = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default observer((props: { store: Store; }) => {
  const { gmsStyleIds, formRef, productTypeOptions, twoPiecesDiscountRule, onGoodsTypeChange, shipmentLimitOptions, goodsProps,
    onPropsItemChange, onPreSaleTypeChange, countryOptions } = props.store;

  const [form] = Form.useForm();
  const skuMultiPriceType = Form.useWatch('skuMultiPriceType', form);
  const skuPriceType = Form.useWatch('skuPriceType', form);
  const preSaleType = Form.useWatch('preSaleType', form);
  const goodsType = Form.useWatch('goodsType', form);

  return (
    <div className={styles.stepTwoContainer}>
      <Alert message={`已选${gmsStyleIds.length}款商品`}/>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        ref={formRef}
        wrapperCol={{ span: 18 }}
      >
        <Title title="基本信息"/>
        <Form.Item
          label="批量设置拼团价"
          required
        >
          <Space size={4}>
            <div>
              拼团价=进货价+
            </div>
            <Form.Item
              name="skuMultiPriceType"
              noStyle
            >
              <Select
                options={PRICE_TYPE_OPTIONS}
                style={{ width: 120 }}
              />
            </Form.Item>
            <Form.Item
              name="skuMultiPriceProfit"
              noStyle
              rules={[
                {
                  required: true,
                  message: '请设置拼团价',
                },
              ]}
            >
              <InputNumber
                addonAfter={skuMultiPriceType === 1 && '%'}
                min={0}
                placeholder={skuMultiPriceType === 0 ? '请输入利润（元）' : '请输入百分比'}
                precision={2}
                style={{ width: 170 }}
              />
            </Form.Item>
            {skuMultiPriceType === 1 && (
              <div>
                *进货价
              </div>
            )}
          </Space>
        </Form.Item>
        <Form.Item
          label="批量设置单买价"
          required
        >
          <Space size={4}>
            <div>
              单买价=进货价+
            </div>
            <Form.Item
              name="skuPriceType"
              noStyle
            >
              <Select
                options={PRICE_TYPE_OPTIONS}
                style={{ width: 120 }}
              />
            </Form.Item>
            <Form.Item
              name="skuPriceProfit"
              noStyle
              rules={[
                {
                  required: true,
                  message: '请设置单买价',
                },
              ]}
            >
              <InputNumber
                addonAfter={skuPriceType === 1 && '%'}
                min={0}
                placeholder={skuPriceType === 0 ? '请输入利润（元）' : '请输入百分比'}
                precision={2}
                style={{ width: 170 }}
              />
            </Form.Item>
            {skuPriceType === 1 && (
              <div>
                *进货价
              </div>
            )}
          </Space>
        </Form.Item>
        <Form.Item label="类目属性">
          <div className={styles.materialWrapper}>
            {goodsProps && goodsProps.map((item, index) => {
              // ... 在此处不同布局的判断
              const key = item.id;
              return (
                <Col
                  className={item.className || ''}
                  key={key}
                  span={item.colspan || 12}
                >
                  <Form.Item
                    {...(item.itemLayout || PROPS_LAYOUT)}
                    initialValue={item.type === 'INPUT' ? item.value : (item.type === 'MULTICHECK' ? (item.value ? item.value.split(',') : undefined) : (item.value || undefined))}
                    label={item.name}
                    name={key}
                    normalize={(value, prevValue, allValues) => {
                      if (item.type === 'MULTICHECK') {
                        if (typeof value === 'string') {
                          return value.split(',');
                        }
                      }
                      return value;
                    }}
                    rules={[
                      item.rules || {
                        required: item.required,
                        message: item.message,
                      },
                    ]}
                  >
                    {item.type === 'INPUT' ? (
                      <Input
                        autoComplete="off"
                        className={item.className || ''}
                        id={key}
                        placeholder={item.placeholder || '请输入'}
                      />
                    ) : (
                      <Select
                        allowClear={item.type !== 'INPUT'}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        mode={item.type === 'MULTICHECK' ? 'multiple' : null}
                        notFoundContent={item.fetching ? <Spin size="small"/> : '无数据'}
                        onChange={(val) => {
                          onPropsItemChange(key, val, index);
                        }}
                        placeholder="请选择"
                        showSearch
                      >
                        {item.checkOptions && item.checkOptions.map((v) => {
                          return (
                            <Select.Option
                              key={v.key}
                              value={v.key}
                            >
                              {v.value}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              );
            })}
          </div>
        </Form.Item>
        <div className={styles.divider}/>
        <Title title="服务与履约"/>
        <Form.Item
          label="商品类型"
          name="goodsType"
          required
        >
          <Radio.Group
            onChange={(e) => onGoodsTypeChange(e.target.value)}
            options={productTypeOptions}
          />
        </Form.Item>
        <RenderByCondition visible={goodsType === 1}>
          <Form.Item
            label="是否二手"
            name="secondHand"
            required
          >
            <Radio.Group options={SECOND_HAND_OPTIONS}/>
          </Form.Item>
        </RenderByCondition>
        <RenderByCondition visible={goodsType !== 1}>
          <Form.Item
            label="货源地"
            name="countryId"
            rules={[
              {
                required: true,
                message: '请选择货源地',
              },
            ]}
          >
            <Select
              options={countryOptions}
              style={{ width: 180 }}
            />
          </Form.Item>
        </RenderByCondition>
        <Form.Item
          label="是否预售"
          name="preSaleType"
          required
        >
          <Radio.Group
            onChange={(e) => onPreSaleTypeChange(e.target.value)}
            options={PRE_SALE_OPTIONS}
          />
        </Form.Item>
        <Form.Item
          label="承诺发货时间"
          required
        >
          <RenderByCondition visible={preSaleType !== 2}>
            <Form.Item
              name="shipmentLimitSecond"
              noStyle
            >
              <Radio.Group options={shipmentLimitOptions}/>
            </Form.Item>
          </RenderByCondition>
          <RenderByCondition visible={preSaleType === 2}>
            <Space>
              <div>
                支付成功后
              </div>
              <Form.Item
                name="shipmentLimitDay"
                noStyle
                rules={[
                  {
                    required: true,
                    message: '请输入承诺发货时间',
                  },
                ]}
              >
                <InputNumber
                  max={15}
                  min={3}
                  precision={0}
                  step={1}
                  style={{ width: 60 }}
                />
              </Form.Item>
              <div>
                天内发货
              </div>
            </Space>
          </RenderByCondition>
        </Form.Item>
        <RenderByCondition visible={preSaleType === 1}>
          <Form.Item
            label="预售时间"
            name="preSaleTime"
            rules={[
              {
                required: true,
                message: '请选择预售时间',
              },
            ]}
          >
            <DatePicker
              className={styles.inputStyle}
              disabledDate={(current) => current && current < moment().add(3, 'days')}
            />
          </Form.Item>
        </RenderByCondition>
        {twoPiecesDiscountRule.ifMustTwoPiecesDiscount && (
          <Form.Item
            initialValue={twoPiecesDiscountRule.recommendTwoPiecesDiscount}
            label="满两件折扣"
            name="twoPiecesDiscount"
            rules={[{ required: true }]}
          >
            <InputNumber
              max={twoPiecesDiscountRule.maxTwoPiecesDiscount}
              min={twoPiecesDiscountRule.minTwoPiecesDiscount}
              style={{ width: 180 }}
            />
          </Form.Item>
        )}
        <Form.Item
          label="拼单要求"
          rules={[{ required: true }]}
        >
          <Input
            disabled
            style={{ width: 180 }}
            suffix="人"
            value="2"
          />
        </Form.Item>
        <Form.Item
          label="承诺"
          required
        >
          <Space direction="vertical">
            <Form.Item
              name="refundable"
              noStyle
              valuePropName="checked"
            >
              <Checkbox
                disabled
                style={{ marginTop: 6 }}
              >
                7天无理由退换货
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="folt"
              noStyle
              valuePropName="checked"
            >
              <Checkbox>
                假一罚十
              </Checkbox>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item
          label="上架时间"
          name="operateType"
        >
          <Radio.Group>
            <Radio value={0}>
              立即上架
            </Radio>
            <Radio value={1}>
              放入草稿箱
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </div>
  );
});
